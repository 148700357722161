<template>
  <div class="endLive-container"></div>
</template>
  
  <script>
import PopopInstance from "@/components/unit/PopupInstance";
import weixin from "@/common/weixin";
export default {
  name: "endLive",
  mounted() {
    document.title = "";
    let content = {
      title: "温馨提示",
      html: `<div class="popup-content-main" style="margin: 20px 0;">会议已结束，感谢观看</div>`,
      btngroup: { sure: "确认" },
      align: "center",
    };
    PopopInstance(content)
      .then(() => {
        weixin.wx.ready(() => {
          weixin.wx.closeWindow();
          // weixin.wx.exitMiniProgram({ complete: () => {} });
        });
      })
      .catch(() => {
        weixin.wx.ready(() => {
          weixin.wx.closeWindow();
          // weixin.wx.exitMiniProgram({ complete: () => {} });
        });
      });
  },
};
</script>
  
<style >
</style>